import { localizeAmount } from '../financials_calculation';

export let feesAmount = () => {
  return totalFees('transaction_fees');
}

export let reimbursementFeesAmount = () => {
  return totalFees('reimbursement_fees');
}


export let charitableDonationsAmount = () => {
  return totalFees('charitable_donations');
}

export let feeToClient = () => {
  return totalFees('fee_to_client');
}

export let allFeesTotal = split => {
  return feesAmount()[split] + reimbursementFeesAmount()[split] + charitableDonationsAmount()[split];
}

let totalFees = feeType => {
  let totalFeesBefore = 0;
  let totalFeesAfter = 0;
  $(`#${feeType}`).children('.fee').each((_, fee) => {
    if ($(fee).is(':visible')) {
      let feeGross = parseFloat($(fee).find('.fee-gross').val() || 0);
      if ($(fee).find('.fee-split').val() === 'fee_before') {
        totalFeesBefore += feeGross;
      } else {
        totalFeesAfter += feeGross;
      }
    }
  });

  return { totalFeesBefore, totalFeesAfter }
}

let setTotalFees = () => {
  let feesTotalHash = feesAmount();
  $('.total-fees-before')
    .html(localizeAmount(feesTotalHash['totalFeesBefore']));
  $('.total-fees-after')
    .html(localizeAmount(feesTotalHash['totalFeesAfter']));
  $('.total-fees')
    .html(localizeAmount(feesTotalHash['totalFeesAfter'] + feesTotalHash['totalFeesBefore']));

  let reimbursementFeesTotalHash = reimbursementFeesAmount();
  $('.reimbursement-total-fees-before')
    .html(localizeAmount(reimbursementFeesTotalHash['totalFeesBefore']));
  $('.reimbursement-total-fees-after')
    .html(localizeAmount(reimbursementFeesTotalHash['totalFeesAfter']));

  let charitableDonationsTotalHash = charitableDonationsAmount();
  $('.charitable-donation-before').html(localizeAmount(charitableDonationsTotalHash['totalFeesBefore']));
  $('.charitable-donation-after').html(localizeAmount(charitableDonationsTotalHash['totalFeesAfter']));

  let feeToClientTotalHash = feeToClient();

  $('.fee-to-client-after').html(localizeAmount(feeToClientTotalHash['totalFeesBefore'] + feeToClientTotalHash['totalFeesAfter']));
}

let checkStandardFeeSelect = () => {
  $('.standard-fee-select').each((_, element) => {
    $(element).closest('.fee-fields').find('.fee-fields--name').hide();
  });
}

let dataFromStandardFeeEvent = () => {
  $('.standard-fee-select').change(e => {
    let selectedOption = $(e.currentTarget).find(':selected');
    let feeRow = selectedOption.closest('.fee-fields');
    if (selectedOption.prop('value') === 'custom_fee') {
      feeRow.find('.fee-fields--name').show();
      feeRow.find('.fee-fields--standard-fee').remove();
    } else {
      feeRow.find('.standard-fee-id').val(selectedOption.prop('value'));
      feeRow.find('.fee-gross').val(selectedOption.data('amount')).trigger('change');
      feeRow.find('.fee-split').val(`fee_${selectedOption.data('split')}`).trigger('change');
      feeRow.find('.fee-name-field').val(selectedOption.data('name'));
      feeRow.find('.fee-name-field').attr('readonly', true);
      feeRow.find('.fee-fields--name').show();
      feeRow.find('.fee-fields--standard-fee').remove();
    }
  });
}

let checkChartiableDonationFeeSelect = () => {
  $('.charitable-donation-select').each((_, element) => {
    $(element).closest('.fee-fields').find('.fee-fields--name').hide();
  });
}

let dataFromChartiableDonationFeeEvent = () => {
  $('.charitable-donation-select').on('change', e => {
    let selectedOption = $(e.currentTarget).find(':selected');
    let feeRow = selectedOption.closest('.fee-fields');
    feeRow.find('.charitable-donation-id').val(selectedOption.prop('value'));
    feeRow.find('.fee-gross').val(selectedOption.data('amount')).trigger('change');
    feeRow.find('.fee-split').val(`fee_${selectedOption.data('split')}`).trigger('change');
    feeRow.find('.fee-name-field').val(selectedOption.data('name'));
    feeRow.find('.fee-name-field').attr('readonly', true);
    feeRow.find('.fee-fields--name').show();
    feeRow.find('.fee-fields--standard-fee').remove();
  });
}


$(document).on('turbolinks:load', () => {
  if ($('.transaction-form').length > 0) {
    setTotalFees();
    checkStandardFeeSelect();
    checkChartiableDonationFeeSelect();
    dataFromStandardFeeEvent();
    dataFromChartiableDonationFeeEvent();
  }

  $('.js-change-total-fees').change(setTotalFees);

  $('.fees-row').on('cocoon:after-insert', e => {
    let insertedFee = $(e.currentTarget).find('.nested-fields').last();
    insertedFee.find('.fee-split').val(insertedFee.data('default-split'));
    $('.js-change-total-fees').change(setTotalFees);
    checkStandardFeeSelect();
    checkChartiableDonationFeeSelect();
    dataFromStandardFeeEvent();
    dataFromChartiableDonationFeeEvent();
  });

  $('.fees-row').on('cocoon:after-remove', setTotalFees);
});
