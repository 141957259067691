import { localizeAmount } from '../financials_calculation';

export let currentPriceAmount = () => {
  return parseFloat($('.transaction-price-field').val() || 0);
}

export let switchPriceTableMode = tableMode => {
  if (tableMode) {
    $('.table-price-field').show();
    $('.table-price-text').hide();
    $('.transaction-price-field.basic').attr('readonly', true);
  } else {
    $('.table-price-field').hide();
    $('.table-price-text').show();
    $('.transaction-price-field.basic').attr('readonly', false);
  }
}

let setPrice = () => {
  $('.transaction-price-field').val(currentPriceAmount());
  $('.table-price-text').find('.amount').html(localizeAmount(currentPriceAmount()));
}

$(document).on('turbolinks:load', () => {
  if ($('.transaction-form').length > 0) {
    setPrice();
  }

  $('.transaction-price-field').change(e => {
    $('.transaction-price-field').val($(e.currentTarget).val());
    setPrice();
  });
});
