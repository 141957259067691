let showMoreListener = () => {
  $('.show-more-button').on('click', (e) => {
    e.preventDefault();
    const btn = $(e.currentTarget);
    let textBlock = btn.closest('.text-block');
    textBlock.find('.show-less-button').show();
    textBlock.find('.more').show();
    btn.hide();
  });
  $('.show-less-button').on('click', (e) => {
    e.preventDefault();
    const btn = $(e.currentTarget);
    let textBlock = btn.closest('.text-block');
    textBlock.find('.show-more-button').show();
    textBlock.find('.more').hide();
    btn.hide();
  });
}

$(document).on('turbolinks:load', showMoreListener);
