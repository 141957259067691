let formHasParams = (form, modelName) => {
  return form.serialize().includes(modelName);
}

let handleSubmit = (modelName) => {
  let form = $('.js-disable-blank-form');
  let submitBtn = form.find('input[type="submit"]');
  if (formHasParams(form, modelName)) {
    submitBtn.removeAttr('disabled');
  } else {
    submitBtn.attr('disabled', 'disabled');
  }
}

let disableBlankForm = (cocoonFieldsSelector, modelName) => {
  handleSubmit(modelName);
  $(cocoonFieldsSelector).on('cocoon:after-insert', () => {
    handleSubmit(modelName);
  });
  $(cocoonFieldsSelector).on('cocoon:after-remove', () => {
    handleSubmit(modelName);
  });
}

$(document).on('turbolinks:load', () => {
  disableBlankForm(
    '#default_dates, div[id*=_documents], #standard_fees, #recurring_fees, #charitable_donations',
    'company'
  );
});
