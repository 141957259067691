// PLUGINS
require('./sweetalert');
require('./select2');
require('./highcharts/main');
require('./moris/main');
require('./rater');
require('./datepicker');
require('./calendar');
require('./ckeditor');

// LAYOUT
require('./header');

//Utilities
require('./dropdowns');
require('./validations');
require('./form');
require('./text_helpers');
require('./disable_blank_form');

//PAGES
require('./task_templates');
require('./tasks');
require('./team_inbox');
require('./transactions');
require('./companies');
require('./inbox_messages');
require('./sortable');
require('./side_search');
require('./historical_side_filter');
require('./leads');
require('./users');
require('./lising');
require('./document');
require('./company_settings');
require('./messages');
require('./transaction_fields');
require('./financials/price');
require('./financials/fees');
require('./financials/payees');
require('./financials/commission_gross');
require('./financials/commission_net');
require('./financials/agent_net');
require('./financials/broker_net');
require('./financials_calculation');
require('./contacts')
