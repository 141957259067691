import { localizeAmount, calculateFinancials } from '../financials_calculation';

let regularPayeesAmount = () => {
  return calculateTotalPayees('regular_payees');
}

let officePayeesAmount = () => {
  return calculateTotalPayees('office_payees');
}

export let totalPayees = payeeTotalSplit => {
  return regularPayeesAmount()[payeeTotalSplit] + officePayeesAmount()[payeeTotalSplit]
}

export let setTotalPayees = () => {
  setRegularPayeeTotal();
  setOfficePayeeTotal();
}

export let initPayeeCalculationEvent = () => {
  $('.payee-field').change(e => {
    if($(e.currentTarget).closest('.payee-row').hasClass('regular_payees_row')) {
      setRegularPayeeTotal();
    } else {
      setOfficePayeeTotal();
    }
    calculateFinancials();
  });
}

let setRegularPayeeTotal = () => {
  let regularPayeesTotalHash = regularPayeesAmount();
  $('.regular-total-payees-before')
    .html(localizeAmount(regularPayeesTotalHash['totalPayeesBefore']));
  $('.regular-total-payees-after')
    .html(localizeAmount(regularPayeesTotalHash['totalPayeesAfter']));
}

let setOfficePayeeTotal = () => {
  let officePayeesTotalHash = officePayeesAmount();
  $('.office-total-payees-before')
    .html(localizeAmount(officePayeesTotalHash['totalPayeesBefore']));
  $('.office-total-payees-after')
    .html(localizeAmount(officePayeesTotalHash['totalPayeesAfter']));
}

let calculateTotalPayees = payeeType => {
  let totalPayeesBefore = 0;
  let totalPayeesAfter = 0;
  $(`.${payeeType}_row`).each((_, payee) => {
    let amountField = $($(payee).find('.payee-amount'));
    let splitField = $($(payee).find('.payee-split'));
    if (amountField.is(':not(:disabled)')) {
      let payeeAmount = parseFloat(amountField.val() || 0);
      if (splitField.val() === 'payee_before') {
        totalPayeesBefore += payeeAmount;
      } else {
        totalPayeesAfter += payeeAmount;
      }
    }
  });

  return { totalPayeesBefore, totalPayeesAfter }
}
