let validateFileSize = file => {
  let fileSize = file.files[0].size / 1024 / 1024;

  if (fileSize >= 25) {
    alert('File size exceeds 25 mb');
    $(file).val('');
  }
}

$(document).on('turbolinks:load', () => {
  $('.file-field').change(e => {
    validateFileSize(e.currentTarget);
  });
});
