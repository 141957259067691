import { blocksSwitcher } from './helpers';
import { initSelect2 } from './select2';

let noteCheckboxEvent = () => {
  $('.js-note-notify-team-checkbox').click(e => {
    let checkbox = $(e.currentTarget).find('input');
    let teamSelect = checkbox.closest('#transaction-simple-note-form')
      .find('.js-notify-team-select');

    if (checkbox.is(':checked')) {
      teamSelect.show();
    } else {
      teamSelect.hide();
    }
  });
}

let noteFormAjaxEvents = () => {
  let element = document.querySelector('#transaction-simple-note-form');
  if (element) {
    element.addEventListener('ajax:success', () => {
      noteCheckboxEvent();
      initSelect2();
      noteFormAjaxEvents();
    });
  }
}

$(document).on('turbolinks:load', () => {
  $('.js-remove-transaction-row').click(e => {
    let row = $(e.currentTarget).closest('tr');

    row.find('[data-toggle="tooltip"]').tooltip('hide');
    row.remove();
  });

  $('.js-edit-property-info').click(e => {
    e.preventDefault();

    blocksSwitcher(
      $('#property-info-block'),
      $('#property-info-fields')
    )
  });

  $('.js-transaction-date-switcher').click(e => {
    e.preventDefault();

    let column = $(e.currentTarget).closest('td');

    blocksSwitcher(
      column.find('.js-transaction-date-string'),
      column.find('.js-transaction-date-field')
    )
  });

  $('#referral-wrapper-toggle').change(e => {
    $('#referral-wrapper').toggle('slow');
    let destroyField = $('#transaction_referral_attributes__destroy');
    let markToDelete = e.target.checked && !destroyField.closest('.associate').hasClass('marked-to-delete');

    destroyField.val(!markToDelete);
  });

  noteCheckboxEvent();
  noteFormAjaxEvents();

  $('#transaction-search .submit-btn').click((e) => {
    e.preventDefault();
    let topSearchInput = $('#transaction-search input');
    let topSearchKey = topSearchInput.attr('name');
    let topSearchQuery = `&q[${topSearchKey}]=${topSearchInput.val()}`;
    let search = decodeURIComponent(window.location.search).replace(/^\?*/, '');
    if (search.match(topSearchKey)) {
      let regex = new RegExp(`&q\\[${topSearchKey}\\]?=.*?(?=$|&)`, 'gi');
      search = search.replace(regex, topSearchQuery);
      window.location.search = encodeURI(search);
    } else {
      window.location.search = encodeURI(`${search}${topSearchQuery}`);
    }
  });

  $('.transaction-form').submit(e => {
    let form = $(e.target);
    if (
      !form.data('persisted')
        && ['buyer', 'seller', 'tenant'].includes(form.data('transaction-type'))
          && $('#transaction_aasm_state').val() === 'closed'
            && $('#clients .associates-collection .associate:not(.marked-to-delete)').length > 0
    ) {
      e.preventDefault();
      $('.finalize_modal_btn').click();
      e.stopPropagation();
    }
  })
});
