import { localizeAmount } from '../financials_calculation';
import { commissionNetAmount } from './commission_net';
import { reimbursementFeesAmount, feesAmount, charitableDonationsAmount} from './fees';
import { brokerNetAmount } from './broker_net';
import { grossAmount } from './commission_gross';
import { totalPayees } from './payees';

export let agentNetPercent = () => {
  return parseFloat($('.transaction-agent-percent-field').val() || 0);
}

export let agentNetAmount = () => {
  return parseFloat($('.transaction-agent-net-field').val() || 0);
}

export let switchAgentNetTableMode = tableMode => {
  if (tableMode) {
    $('.table-agent-net-field').show();
    $('.table-agent-net-text').hide();
    $('.transaction-agent-net-field.basic').attr('readonly', true);
    $('.transaction-agent-percent-field').attr('readonly', true);
  } else {
    $('.table-agent-net-field').hide();
    $('.table-agent-net-text').show();
    $('.transaction-agent-net-field.basic').attr('readonly', false);
    $('.transaction-agent-percent-field').attr('readonly', false);
  }
}

export let setAgentNet = () => {
  $('.transaction-agent-net-field').val(agentNetAmount());
  $('.table-agent-net-text').find('.amount').html(localizeAmount(agentNetAmount()));
  $('.table-agent-net-percent').html(agentNetPercent());
}

export let setAgentNetFromBrokerNet = () => {
  let amount = grossAmount() - brokerNetAmount();
  amount -= totalPayees('totalPayeesAfter');
  amount -= totalPayees('totalPayeesBefore');

  $('.transaction-agent-net-field').val(amount.toFixed(2)).trigger('change');
}

export let setAgentNetAmount = () => {
  $('.transaction-agent-net-field').val(calculateAgentNetAmount());
  setAgentNet();
}

let setAgentNetPercent = () => {
  $('.transaction-agent-percent-field').val(calculateAgentNetPercent());
  setAgentNet();
}

let calculateAgentNetAmount = () => {
  let agentNetAmount;
  let reimbursementFees = reimbursementFeesAmount();
  let charitableDonations = charitableDonationsAmount();

  agentNetAmount = (agentNetPercent() / 100) * commissionNetAmount();
  agentNetAmount -= feesAmount()['totalFeesAfter'];
  agentNetAmount -= totalPayees('totalPayeesAfter');
  agentNetAmount += reimbursementFees['totalFeesAfter'];
  agentNetAmount += reimbursementFees['totalFeesBefore'];
  agentNetAmount -= charitableDonations['totalFeesAfter'];

  return agentNetAmount.toFixed(2);
}

let calculateAgentNetPercent = () => {
  let agentNetPercent;
  let reimbursementFees = reimbursementFeesAmount();
  let charitableDonations = charitableDonationsAmount();

  agentNetPercent = agentNetAmount() - reimbursementFees['totalFeesBefore'];
  agentNetPercent -= reimbursementFees['totalFeesAfter'];
  agentNetPercent += feesAmount()['totalFeesAfter'];
  agentNetPercent += charitableDonations['totalFeesAfter'];
  agentNetPercent += totalPayees('totalPayeesAfter');
  agentNetPercent = (agentNetPercent / commissionNetAmount()) * 100;

  return agentNetPercent
}

$(document).on('turbolinks:load', () => {
  if ($('.transaction-form').length > 0) {
    setAgentNet();
  }

  $('.transaction-agent-net-field').change(e => {
    $('.transaction-agent-net-field').val($(e.currentTarget).val());
    setAgentNetPercent();
  });

  $('.transaction-agent-percent-field').change(e => {
    $('.transaction-agent-percent-field').val($(e.currentTarget).val());
    setAgentNetAmount();
  });
});
