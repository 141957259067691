let initListeners = (item) => {
  item.find('.company-settings-datepicker').datepicker({
    format: 'yyyy-mm-dd',
    clearBtn: true
  });

  item.find('.company-settings-select2').select2({
    width: '100%'
  });

  item.find('.show-options').click(e => {
    e.preventDefault();
    $(e.target).closest('.default-date').find('.default-date-options').toggle('slow');
  });
}

let setListenersFor = (formSelector) => {
  initListeners($(formSelector));
  $(formSelector).on('cocoon:after-insert', (e, insertedItem, _originalEvent) => {
    initListeners(insertedItem);
  });
}

$(document).on('turbolinks:load', () => {
  setListenersFor('#default_dates, #standard_fees, #recurring_fees, #charitable_donations');
});

$(document).on('turbolinks:before-cache', () => {
  $('.company-settings-select2').each((index, element) => {
    $(element).select2('destroy');
  });
});
