import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import AxiosRequest from './axios_request';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone/moment-timezone';
import { swalConfirm, toastSuccess, toastError } from './sweetalert';

export default {
  components: { DatePicker },
  props: {
    task: Object,
    field: String,
    type: String,
    taskAssignees: Array
  },
  data() {
    return {
      id: this.task.id,
      dueDate: this.task.due_date != null ? moment.utc(this.task.due_date).format() : null,
      title: this.task.title,
      completed: this.task.completed,
      editUrl: this.task.edit_field_url,
      deleteUrl: this.task.delete_task_path,
      userId: this.task.user_id,
      titleFocused: false,
      loading: false
    };
  },
  watch: {
    task: {
      deep: true,
      handler() {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    completed(newVal, oldVal) {
      if (newVal !== oldVal && this.completed !== this.task.completed) {
        this.updateTask('completed', newVal);
      }
    },
    dueDate(newDate, oldDate) {
      if ((oldDate == null || newDate !== oldDate) && this.dueDate !== moment.utc(this.task.due_date).format()) {
        this.updateTask('due_date', newDate);
      }
    },
    title(newTitle, oldTitle) {
      if (newTitle !== oldTitle && this.title !== this.task.title) {
        this.updateTask('title', newTitle);
      }
    },
    userId(newId, oldId) {
      if (newId !== oldId && this.userId !== this.task.user_id) {
        this.updateTask('user_id', newId);
      }
    }
  },
  methods: {
    titleText(e) {
      return e.target.closest('.title-form').querySelectorAll('.inline-input')[0].innerText;
    },
    titleUpdate(e) {
      this.updateTask('title', this.titleText(e))
    },
    titlesUpdate(e) {
      this.$emit('bulk-update', 'title', this.titleText(e));
    },
    titlesAppend(e) {
      this.$emit('bulk-append-title', this.titleText(e).replace(this.title, ''));
    },
    finderData() {
      return JSON.parse(this.$el.closest('li[data-find]').dataset.find);
    },
    select() {
      this.$emit('update-selected', this.finderData());
    },
    hideTask(task) {
      this.$set(task, 'show', false);
    },
    updateTaskResponseHandler(response, field, value) {
      if (this.type === 'liveTasks' && field === 'completed') {
        this.hideTask(this.task);
      }
      let message = `Task '${this.task.title}' has been successfully updated.`;
      if (field === 'completed') {
        if (value) {
          message += ' Task completed now.';
        } else {
          message += ' Task uncompleted now.';
        }
      }
      toastSuccess(this, message);
    },
    updateTask(field, newValue) {
      if (this.task.selected) {
        return this.$emit('bulk-update', field, newValue);
      }
      AxiosRequest({
        method: 'post',
        url: this.editUrl,
        data: {
          task: {
            [field]: newValue
          }
        }
      }).then(response => {
        Object.assign(this.task, response.data.task);
        this.updateTaskResponseHandler(response, field, newValue);
      }).catch(error => {
        if (error.response.status === 422) {
          return toastError(this, `Task wasn't updated. ${error.response.data.errors}`);
        }
        toastError(this);
      });
    },
    async deleteTask() {
      if (this.task.selected) {
        return this.$emit('bulk-delete');
      }
      await swalConfirm(this).then(response => {
        if (response['isConfirmed']) {
          AxiosRequest({
            method: 'delete',
            url: this.deleteUrl,
            data: { handler: 'vue' }
          }).then(() => {
            toastSuccess(this, `Task '${this.title}' was successfully deleted.`);
            this.hideTask(this.task);
          }).catch(() => {
            toastError(this);
          });
        }
      });
    },
  }
}
