$(document).on('turbolinks:load', () => {
  $('.js-document-publicly-checkbox').change(() => {
    let documentId, transactionId;
    documentId = $(event.target).data('document-id');
    transactionId = $(event.target).data('transaction-id');
    return $.ajax({
      url: `/v2/documents/transaction/${documentId}?transaction_id=${transactionId}`,
      dataType: 'script',
      data: {
        transaction_document: {
          publicly_available: $(event.target).is(':checked')
        }
      },
      method: 'PATCH'
    });
  });
});
