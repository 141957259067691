import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import Task from './task';
import TaskList from './task_list';
import Associates from './associates';
import MessagesList from './messages_list';
import ContactsFinalize from './contacts_finalize';
import { BootstrapVue } from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSanitize from 'vue-sanitize-directive'

Vue.use(TurbolinksAdapter);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VueSanitize);

Vue.component('task-list', TaskList);
Vue.component('task', Task);
Vue.component('associates', Associates);
Vue.component('messages-list', MessagesList);
Vue.component('contacts-finalize', ContactsFinalize);

window.Vue = Vue

$(document).on('turbolinks:load', () => {
  $('[data-behaviour="vue"]').each((_, element) => {
    const app = new Vue({
      el: element
    });
  });
});
