// TODO remove this file after historical report improvement
let triggerSideFormChange = () => {
  if ($('#transactions-historical-filter-form').is(':visible')) {
    $('#transactions-historical-filter-form').trigger('change');
  }
}

let triggerAllCheckboxes = (element, inputs, action) => {
  element.click((e) => {
    e.preventDefault();
    inputs.prop("checked", action);
    triggerSideFormChange();
  });
}

$(document).on('turbolinks:load', () => {
  $('#transactions-historical-filter-form').change(() => {
    if ($("input[name='q[aasm_state_eq_any][]']").length &&
      $("input[name='q[aasm_state_eq_any][]']:checked").length == 0){
      return alert('Please select at least one status');
    } else if ($("input[name^='q[years]']").length &&
      $("input[name^='q[years]']:checked").length == 0) {
      return alert('Please select at least one year');
    }

    $('#transactions-historical-filter-form').submit();
  });

  triggerAllCheckboxes(
    $('#transactions-select-all-leads-historical'),
    $("input[name='q[lead_id_eq_any][]']"),
    true
  );

  triggerAllCheckboxes(
    $('#transactions-unselect-all-leads-historical'),
    $("input[name='q[lead_id_eq_any][]']"),
    false
  );

  $('.js-historical-disable-other-statuses').click(e => {
    let target = $(e.target);

    if (target.is(':checked')) {
      $('.historical-statuses').each((_, element) => {
        if ($(element).val() != target.val()) {
          $(element).prop('checked', false);
        }
      });
    }
  });

  $('.js-historical-check-only-seller').click(e => {
    if ($(e.currentTarget).is(':checked')) {
      $('#buyer-type-checkbox').prop('checked', false);
      $('#seller-type-checkbox').prop('checked', true);
    }
  });

  $('.js-disable-listed-type').click(e => {
    if ($(e.currentTarget).is(':checked')) {
      $('#listed-state-checkbox').prop('checked', false);
    }
  });
})
