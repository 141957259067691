function initUserTotalChart (element) {
  if (element.length && element.is(':empty') && element.data('report-data').length > 0) {
    var userData = element.data('report-data');

    element.css({ 'height': userData.length * 300 + 'px' });
    
    function goal(userData) {
      if (userData[0]['goals_enabled']) {
       let goalData = userData.map(data => {
         return data['public_goal'] && data['goal'] ? data['goal'] : -1
       });

        return {
          name: 'goal',
          data: goalData,
          color: '#8EC2F2'
        }
      } else {
        return {}
      }
    }

    function setChartData(data) {
      return data > 0 ? data : null
    }

    element.highcharts({
      chart: {
        type: 'bar',
        scrollablePlotArea: {
          minWidth: '420',
          scrollPositionX: 0
        }
      },
      title: {
        text: null
      },
      xAxis: {
        categories: userData.map(data => data['user_name']),
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: true,
            align: 'left',
            color: '#000',
            shadow: false,
            useHTML: true,
            formatter: function() {
              if(this.point.series.name != 'goal') {
                return '$' + Highcharts.numberFormat(this.point.y, 0, '.', ',') + ' - ' + this.point.series.name;
              } else if(this.point.y === -1) {
                return '<a href="/v2/user/edit" target="_blank">Please enter your current year goal</a>'
              } else {
                return '$' + Highcharts.numberFormat(this.point.y, 0, '.', ',') + ' - ' + new Date().getFullYear() + ' Goal';
              }
            }
          },
          states: {
            hover: {
              color: '#CD5EA3'
            },
            inactive: {
              opacity: 1
            }
          }
        },
        series: {
          animation: false,
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Listed / Coming Soon',
          data: userData.map(data => setChartData(data['listed_coming_soon']['sales'])),
          color: '#8EC2F2'
        },
        {
          name: 'Prior YTD Contracted & Closed',
          data: userData.map(data => setChartData(data['prior_year_contracted']['sales'])),
          color: '#8EC2F2'
        },
        {
          name: 'YTD Contracted & Closed',
          data: userData.map(data => setChartData(data['current_year_contracted']['sales'])),
          color: '#8EC2F2'
        },
        (goal(userData)),
        {
          name: 'Past 12 months',
          data: userData.map(data => setChartData(data['twelve_months']['sales'])),
          color: '#8EC2F2'
        },
        {
          name: 'Last Year',
          data: userData.map(data => setChartData(data['last_year']['sales'])),
          color: '#8EC2F2'
        }
      ]
    });
  }
}

$(document).on('render_async_load', () => {
  initUserTotalChart($('#dashboard-users-report'));
});
