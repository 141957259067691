let onMenuClick = (event) => {
  event.preventDefault();
  const nextEl = event.target.nextSibling.nextSibling;
  if (nextEl && !nextEl.classList.contains("show")) {
    const parentEl = event.target.parentNode;
    if (parentEl) {
      parentEl.classList.remove("show");
    }
    nextEl.classList.add("show");
  } else if (nextEl) {
    nextEl.classList.remove("show");
  }
  return false;
}

let toggleMenu = () => {
  let element = document.getElementById("topnav-menu-content");
  element.classList.toggle("show");
}

let initFullScreen = () => {
  document.body.classList.toggle("fullscreen-enable");
  if (
    !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}

$(document).on('turbolinks:load', () => {
  $('.submenu-link').click(onMenuClick);
  $('.toggle-menu').click(toggleMenu);
  $('.init-fullscreen').click(initFullScreen);
})
