import { localizeAmount } from '../financials_calculation';
import { grossAmount, setGrossFromCommissionNet } from './commission_gross';
import { feesAmount, reimbursementFeesAmount, charitableDonationsAmount } from './fees';
import { totalPayees } from './payees';

export let commissionNetAmount = () => {
  return parseFloat($('.commission-net-field').val() || 0);
}

export let setCommissionNet = () => {
  let amount = calculateCommissionNetAmount();
  $('.commission-net-field').val(amount);
  $('.table-commission-net-text').find('.amount').html(localizeAmount(amount));
}

export let switchCommissionNetTableMode = tableMode => {
  if (tableMode) {
    $('.table-commission-net-text').hide();
    $('.table-commission-net-field').show();
  } else {
    $('.table-commission-net-text').show();
    $('.table-commission-net-field').hide();
  }
}

let calculateCommissionNetAmount = () => {
  let amount = grossAmount() - feesAmount()['totalFeesBefore'];
  amount -= reimbursementFeesAmount()['totalFeesBefore'];
  amount -= charitableDonationsAmount()['totalFeesBefore'];
  amount -= totalPayees('totalPayeesBefore');

  return amount.toFixed(2);
}

$(document).on('turbolinks:load', () => {
  if ($('.transaction-form').length > 0) {
    setCommissionNet();
  }

  $('.table-commission-net-field').change(() => {
    $('.table-commission-net-text').find('.amount').html(localizeAmount(commissionNetAmount()));
    setGrossFromCommissionNet();
  });
});