import Sortable from "sortablejs";

export let initSortable = (el) => {
  if (screen.width > 768) {
    new Sortable(el, {
      animation: 150,
      filter: ".btn",
      onEnd: () => {
        initReorder(el);
      },
    });
  }
}
export let initReorder = (el) => {
  let sortable_ids = [];
  Array.from(el.children).forEach(children => {
    sortable_ids.push(children.id)
  })
  $.ajax({
    type: 'POST',
    url: '/sortable/reorder',
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({
      rails_sortable: sortable_ids
    }),
  });
}

$(document).on('turbolinks:load', () => {
  $('.sortable').each((_i, el) => {
    initSortable(el)
  });
});
