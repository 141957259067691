let ready = () => {
  $('.toggle').click(e => {
    e.preventDefault();
    let target = $(e.currentTarget)
    let filters = target.closest('aside').find('.filters')
    target.toggleClass('active');
    filters.toggleClass('active');
  });

  $('.slide').click(e => {
    e.preventDefault();
    let target = $(e.currentTarget)
    let moreFilters = target.closest('aside, .power-search--form').find('.search-more')
    target.toggleClass('active');
    moreFilters.toggleClass('active');
  });
}

$(document).on('turbolinks:load', ready);
