let hasRecipients = (selectInput) => {
  return selectInput.length > 0 && !selectInput.data('has-recipients')
}

let selectAllRecipients = selectInput => {
  if (selectInput.data('send-to-all')) {
    selectInput.find('option').prop('selected', true).trigger('change');
  }
}

let buildRecipient = (select, recipient_type = null) => {
  let recipients = [];

  select.find(':selected').each((_, element) => {
    recipients.push({
      recipient_id: $(element).val(),
      recipient_type: recipient_type ? recipient_type : $(element).data('resource-class')
    });
  });

  return recipients;
}

let setMessageRecipients = () => {
  let message_recipients = [];

  message_recipients.push(...buildRecipient($('#messages-our-team-select'), 'User'));
  message_recipients.push(...buildRecipient($('#messages-clients-select')));
  message_recipients.push(...buildRecipient($('#messages-other-recipients-select')));
  $('#new_message').append('<input type="hidden" id="message_recipients" name="message_recipients">');
  $('#message_recipients').val(JSON.stringify(message_recipients));
}

$(document).on('turbolinks:load', () => {
  $('#new_message').submit(() => {
    setMessageRecipients();

    return true;
  });

  $('.js-update-task-form').on('ajax:success', e => {
    let data = e.detail[0];
    $('.task-title').html(`${data['user_name'] || ''} ${data['title']} `);
    if (data['parent_uid']) {
      $('.task-title').append(`(${data['parent_uid']})`);
    }
    $('.task-due-date').html(data['due_date']);
    $('.js-update-task-form').find('input').removeClass('is-invalid');
    $('.js-update-task-form').find('.invalid-feedback').remove();
    $('.edit-task-form .errors').html('');
    $('#edit-task-modal').modal('hide');
  }).on('ajax:error', e => {
    $('.edit-task-form .errors').html('');
    let data = e.detail[0];
    $.each(data, (key, value) => {
      if ($(`#task_${key}`).length > 0) {
        $(`#task_${key}`).addClass('is-invalid');
        $(`#task_${key}`).after(`
        <span class="help-inline invalid-feedback">
          ${key[0].toUpperCase() + key.slice(1)} 
          ${value}
        </span>
      `);
      } else {
        $('.edit-task-form .errors')
          .append(`<div class="text-danger mb-2">${value}</div>`);
      }
    });
  });

  if (hasRecipients($('#messages-our-team-select'))) {
    selectAllRecipients($('#messages-our-team-select'));

    if ($('#messages-our-team-select').data('send-to-agent')) {
      $(`#messages-our-team-select option[value="${$('#messages-our-team-select').data('send-to-agent')}"]`)
        .prop('selected', true)
        .trigger('change');
    }
  }

  if (hasRecipients($('#messages-clients-select'))) {
    selectAllRecipients($('#messages-clients-select'));
  }

  if (hasRecipients($('#messages-other-recipients-select'))) {
    $('#messages-other-recipients-select option[data-checked="true"]')
      .prop('selected', true)
      .trigger('change');
  }

  $('#message-attachments').on('cocoon:after-insert', (e, addedEl) => {
    $(addedEl).find('input').click();
  });
});
