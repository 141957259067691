import AxiosRequest from './axios_request';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import { toastError } from './sweetalert';
import StarRating from 'vue-star-rating'
import debounce from 'lodash/debounce';
import _ from 'lodash';
import { createFubContact, fubGetContacts } from './fub';
import { setTotalPayees, initPayeeCalculationEvent } from '../financials/payees';
import { calculateFinancials } from '../financials_calculation';
import { sanitizeText } from '../helpers';

export default {
  props: {
    associatesModule: String,
    maxOne: Boolean,
    associatesData: Object,
    polymorphicType: String,
    fubIntegration: Boolean,
    defaultPayeeSplit: String,
    editable: Boolean,
    creatable: Boolean
  },
  components: { VueBootstrapTypeahead, StarRating, DatePicker },
  data() {
    return {
      typeaheadInputValue: '',
      localSuggestions: [],
      typeaheadSuggestions: [],
      associatesCollection: [],
      loading: true,
      editableItem: null,
      errorMessages: [],
      errorKeys: [],
      fubLoading: false,
      fubLoaded: '',
      duplicateId: null,
      loadingNewAssociate: false,
    };
  },
  computed: {
    isAddingDisabled() {
      return this.maxOne && !!this.associatesCollection.find(el => el.should_delete === false);
    },
    isCreateDisabled() {
      return this.creatable;
    }
  },
  created() {
    AxiosRequest({
      method: 'post',
      url: `/v2/suggested_contacts?type=${this.associatesModule}`,
    }).then(response => {
      this.localSuggestions = response.data.suggestions;
      this.typeaheadSuggestions = this.localSuggestions;
      this.associatesCollection = this.associatesData.associates;
      this.loading = false;
    }).catch(e => {
      if (e.response) {
        toastError(this);
      }
    });

  },
  watch: {
    typeaheadInputValue: debounce(function (newValue, _old) {
      this.getFubSuggestions(newValue);
    }, 500)
  },
  updated() {
    if (['regular_payees', 'office_payees'].includes(this.associatesModule) &&
      this.associatesCollection.length > 0) {
      setTotalPayees();
      calculateFinancials();
      initPayeeCalculationEvent();
    }
  },
  methods: {
    //actions
    hitTypeaheadSuggestion(suggestion) {
      this.addAssociate(suggestion);
      this.$refs.associateTypeahead.inputValue = '';
      this.typeaheadInputValue = '';
      this.fubLoaded = '';
      this.typeaheadSuggestions = this.localSuggestions;
    },
    //contact actions
    showModal(suggestion) {
      AxiosRequest({
        method: 'get',
        url: suggestion.edit_url
      }).then(response => {
        this.editableItem = Object.assign({}, response.data);
        this.editableItem.restrict_name_changes = true;
        this.editablePath = this.editableItem.update_url;
      }).catch(() => {
        toastError(this);
      });
      this.editableMethod = 'patch';
    },
    showModalNew() {
      this.editableItem = {
        check_duplicate: true,
        address_attributes: {}
      };
      this.editableMethod = 'post';
      this.editablePath = `/v2/contacts`;
    },
    closeModal() {
      this.editableItem = null;
      this.errorMessages = [];
      this.errorKeys = [];
    },
    // Contact Sub-modal actions
    useExisting() {
      let suggestion = this.localSuggestions.find(el => el.suggestion_id.toString() === this.duplicateId);
      this.addAssociate(suggestion);
      this.hideModals();
    },
    createNew() {
      this.editableItem.check_duplicate = false;
      this.submitAssociate();
    },
    modalSubmit() {
      this.submitAssociate();
    },
    deleteItem(item) {
      item.should_delete = !item.should_delete;
      if (['regular_payees', 'office_payees'].includes(this.associatesModule)) {
        setTotalPayees();
        calculateFinancials();
      }
    },
    payeeSplit(payee) {
      return payee.split || this.defaultPayeeSplit;
    },
    // private
    async getFubSuggestions(inputValue) {
      if (inputValue.length > 2 && this.fubIntegration) {
        this.fubLoading = true;
        this.typeaheadSuggestions = this.localSuggestions;
        fubGetContacts(inputValue).then(data => {
          let localItems = this.$refs.associateTypeahead.$refs.list.matchedItems.map(item => {
            return item.data;
          }).slice(0, 10);
          let fubItems = _.reject(data, item => {
            return localItems.some(localItem => {
              return item.name === localItem.name;
            })
          }).slice(0, 5);
          fubItems.length > 0 ? this.fubLoaded = 'loaded' : this.fubLoaded = 'not_found';
          Array.prototype.push.apply(localItems, fubItems);
          this.typeaheadSuggestions = localItems;
          this.fubLoading = false;
        })
      } else {
        this.fubLoaded = '';
        this.typeaheadSuggestions = this.localSuggestions;
      }
    },
    addAssociate(suggestion) {
      if (suggestion.class_name === 'User') {
        this.associatesCollection.push(this.assignContact({}, suggestion, 'User'));
      } else {
        this.loadingNewAssociate = true;
        this.getContactData(suggestion, suggestion.fub ? 'fub' : 'local').then(contactData => {
          this.associatesCollection.push(this.assignContact({}, contactData));
          this.loadingNewAssociate = false;
        }).catch(() => {
          toastError(this);
          this.loadingNewAssociate = false;
        });
      }
    },
    submitAssociate() {
      AxiosRequest({
        method: this.editableMethod,
        url: this.editablePath,
        data: {
          contact: this.editableItem
        }
      }).then(response => {
        if (this.editableMethod === 'patch') {
          let filteredItems = this.associatesCollection.filter(el => el.associate_id === this.editableItem.id);
          filteredItems.forEach(item => this.assignContact(item, response.data));
        } else {
          this.associatesCollection.push(this.assignContact({}, response.data));
        }
        this.hideModals();
      }).catch(error => {
        if (error.response.status === 422) {
          this.errorMessages = error.response.data.messages;
          this.errorKeys = Object.keys(error.response.data.errors);
          if (this.errorKeys.includes('check_duplicate')) {
            this.$bvModal.show(`${this.associatesModule}_modal_duplicate`);
            this.duplicateId = error.response.data.errors.check_duplicate[0];
          }
        } else {
          toastError(this);
        }
      });
    },
    getContactData(suggestion, type) {
      if (type === 'fub') {
        return createFubContact(suggestion);
      } else {
        return AxiosRequest({
          method: 'get',
          url: suggestion.edit_url
        }).then(response => {
          return response.data;
        });
      }
    },
    assignContact(item, data, suggestionClass = 'Contact') {
      let assignData = {}
      if (suggestionClass === 'Contact') {
        assignData = {
          name: data.name,
          details: data.details,
          rating: data.rating,
          update_url: data.update_url,
          show_url: data.show_url,
          edit_url: data.edit_url,
          associate_id: data.id,
          associate_type: data.associate_type,
          should_delete: item.should_delete || false,
          address_attributes: data.address_attributes,
          organization: data.organization || ''
        }
      } else {
        assignData = {
          name: data.name,
          details: data.details,
          should_delete: false,
          associate_id: data.suggestion_id,
          associate_type: 'User'
        }
      }

      Object.assign(item, assignData);

      return item;
    },
    inputId(index, field) {
      let idIndexSegment = this.maxOne ? '' : `_${index}`;
      return `transaction_${this.associatesModule}_attributes${idIndexSegment}_${field}`;
    },
    inputName(index, field) {
      let nameIndexSegment = this.maxOne ? '' : `[${index}]`;
      return `transaction[${this.associatesModule}_attributes]${nameIndexSegment}[${field}]`;
    },
    hideModals() {
      this.$bvModal.hide(`${this.associatesModule}_modal`);
      this.$bvModal.hide(`${this.associatesModule}_modal_duplicate`);
    },
    sanitize(text){
      return sanitizeText(text);
    }
  }
}
