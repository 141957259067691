import { localizeAmount } from '../financials_calculation';
import { grossAmount } from './commission_gross';
import { feeToClient } from './fees';
import { totalPayees } from './payees';
import {
  agentNetPercent,
  setAgentNetFromBrokerNet,
  agentNetAmount
} from './agent_net';

export let brokerNetAmount = () => {
  return parseFloat($('.broker-net-field').val() || 0);
}

export let switchBrokerNetTableMode = tableMode => {
  if (tableMode) {
    $('.table-broker-net-text').hide();
    $('.table-broker-net-field').show();
  } else {
    $('.table-broker-net-text').show();
    $('.table-broker-net-field').hide();
  }
}

export let setBrokerNet = () => {
  let amount = calculateBrokerNetAmount();
  $('.broker-net-field').val(amount);
  $('.table-broker-net-text').find('.amount').html(localizeAmount(amount));
  $('.broker-net-percent').html(calculateBrokerNetPercent());
}

let calculateBrokerNetAmount = () => {
  let feeToclientFee = feeToClient();
  let amount = grossAmount() - agentNetAmount()
  amount -= totalPayees('totalPayeesBefore');
  amount -= totalPayees('totalPayeesAfter');
  amount += feeToclientFee['totalFeesBefore'];

  return amount.toFixed(2);
}

let calculateBrokerNetPercent = () => {
  return (100 - agentNetPercent())
}

$(document).on('turbolinks:load', () => {
  $('.broker-net-field').change(setAgentNetFromBrokerNet);
});
