import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

Rails.confirm = (message, link) => {
  let $link = $(link)

  if ($link.data('confirm') == undefined) {
    return true;
  }

  Rails.showConfirmationDialog($link);
  return false;
}

Rails.confirmed = link => {
  if (link.attr('type') === 'submit') {
    link.closest('form').submit();
  } else {
    $.ajax({
      url: link.attr('href'),
      method: link.data('method'),
      dataType: 'script'
    });
  }
}

Rails.showConfirmationDialog = link => {
  var message = link.data('confirm');
  Swal.fire({
    title: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#34c38f',
    cancelButtonColor: '#f46a6a',
    confirmButtonText: 'Yes'
  }).then(result => {
    if (result.value) {
      Rails.confirmed(link);
    }
  });
};

export let swalConfirm = params => {
  return Swal.fire({
    titleText: params.title || '',
    text: params.message || '',
    icon: 'warning',
    showCancelButton: params.hasCancel || false,
    confirmButtonColor: '#34c38f',
    cancelButtonColor: '#f46a6a',
    confirmButtonText: params.confirmButton || 'Yes'
  });
}

export let toastSuccess = (message = 'Success!') => {
  toast(message, 'success');
}

export let toastError = (message = 'OOps, something went wrong!') => {
  toast(message, 'error');
}

let toast = (message, type) => {
  return Swal.fire({
    titleText: message,
    toast: true,
    icon: type,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    animation: false,
    customClass: `task-list-toast ${type}`
  });
}
