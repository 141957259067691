let initBarGraph = (elemSelector) => {
  elemSelector.highcharts({
    chart: {
      type: 'bar'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: [''],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'left',
          color: '#000000',
          shadow: false,
          format: '${point.y:,.0f} - {point.series.name}'
        },
        states: {
          hover: {
            color: '#CD5EA3'
          },
          inactive: {
            opacity: 1
          }
        }
      },
      series: {
        animation: false,
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: elemSelector.data('chart-data')
  });
};

$(document).on('turbolinks:load', () => {
  $('.historical_highcharts').each((i, item) => {
    initBarGraph($(item));
  });
});
