import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

let initCalendar = (element_id, events = []) => {
  let calendarEl = document.getElementById(element_id);

  let calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin],
    defaultView: 'dayGridMonth',
    headerToolbar: {
      left: 'title',
      right: 'today prev,next'
    },
    initialDate: Date.now(),
    navLinks: true,
    events: events
  });

  calendar.render();

  return calendar;
}

let refreshCalendarEvents = (calendar, url, data = {}) => {
  return $.ajax({
    url: url,
    method: 'GET',
    dataType: 'json',
    data: data,
    success: responce => {
      calendar.removeAllEvents();
      calendar.addEventSource(responce.events);
    }
  });
}

$(document).on('turbolinks:load', () => {
  if ($('#calendar').length > 0) {
    let calendar = initCalendar('calendar', $('#calendar').data('events'));
    $('.fc-header-toolbar').after($('.calendar-agent-select-block'));

    $('.js-change-calendar-user').change(e => {
      refreshCalendarEvents(
        calendar,
        '/v2/calendar',
        { agent_id: $(e.currentTarget).val() }
      ).then(responce => {
        let subscription = responce.subscription;
        $('.webcal-subscription-link').text(subscription.webcal_url_text);
        $('.webcal-subscription-link').attr('href', subscription.webcal_url);
        $('.http-subscription-link').val(subscription.http_url);
      })
    });
  }
});
