import Highcharts from 'highcharts'
window.Highcharts = Highcharts;

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ','
  }
});

require('./user_total');
require('./office_total');
require('./historical');
