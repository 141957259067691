import { switchPriceTableMode } from './financials/price';
import { setGrossAmount, switchGrossTableMode } from './financials/commission_gross';
import { setCommissionNet, switchCommissionNetTableMode } from './financials/commission_net';
import { setAgentNetAmount, switchAgentNetTableMode } from './financials/agent_net';
import { setBrokerNet, switchBrokerNetTableMode } from './financials/broker_net';

export let localizeAmount = amount => {
  return Number(amount).toLocaleString();
}

export let calculateFinancials = () => {
  setGrossAmount();
  setCommissionNet();
  setAgentNetAmount();
  setBrokerNet();
}

let zeroAutoSetEvent = () => {
  $('.financial-field').change(e => {
    if ($(e.currentTarget).val().length == 0) {
      $(e.currentTarget).val(0);
    }
  });
}

let initCalculationEvent = () => {
  $('.js-calculate-financials').change(calculateFinancials);
}

$(document).on('turbolinks:load', () => {
  if ($('.transaction-form').length > 0) {
    calculateFinancials();
    initCalculationEvent();
    zeroAutoSetEvent();
  }

  $('#switch_mode').change(e => {
    let tableMode = $(e.currentTarget).is(':checked');
    switchPriceTableMode(tableMode);
    switchGrossTableMode(tableMode);
    switchCommissionNetTableMode(tableMode);
    switchAgentNetTableMode(tableMode);
    switchBrokerNetTableMode(tableMode);
  });

  $('.js-calculate-financials').change(calculateFinancials);

  $('.fees-row').on('cocoon:after-insert', () => {
    initCalculationEvent();
    zeroAutoSetEvent();
  });

  $('.fees-row').on('cocoon:after-remove', calculateFinancials);
});
