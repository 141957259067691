let deactivateLead = (deactivateType) => {
  $.ajax({
    url: `/v2/leads/${$('#lead_id').val()}`,
    method: 'PATCH',
    async: false,
    data: {
      lead: { active: false },
      combine_lead_id: $('#combine_lead_id').val(),
      deactivate_type: deactivateType
    }
  });
}

$(document).on('turbolinks:load', () => {
  $('.js-lead-deactivate-form').click(e => {
    let target = $(e.currentTarget);

    $('#lead_id').val(target.data('lead-id'));
  });

  $('.js-lead-deactivate-only').click(e => {
    e.preventDefault();

    deactivateLead('deactivate_only');
  });

  $('.js-lead-assign-and-deactivate').click(e => {
    e.preventDefault();

    deactivateLead('reassign');
  });
})
