let goals = (data) => {
  if(data['goal_enabled']){
   return  {
     name: 'Present Year Goal',
     data: [data['company_goal']],
     color: '#8EC2F2'
   }
  } else {
    return {}
  }
}

let initOfficeChart = element => {
  if (element.length && element.is(':empty')) {
    let officeData = element.data('report-data');

    element.highcharts({
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: [''],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: true,
            align: 'left',
            color: '#000000',
            shadow: false,
            format: '${point.y:,.0f} - {point.series.name}',
            style: {
              textOutline: false
            }
          },
          states: {
            hover: {
              color: '#CD5EA3'
            },
            inactive: {
              opacity: 1
            }
          }
        },
        series: {
          animation: false,
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Listed / Coming Soon',
          data: [officeData['listed_coming_soon'] || 0],
          color: '#8EC2F2'
        },
        {
          name: 'Prior YTD Contracted & Closed',
          data: [officeData['prior_year_contracted'] || 0],
          color: '#8EC2F2'
        },
        {
          name: 'YTD Contracted & Closed',
          data: [officeData['year_contracted'] || 0],
          color: '#8EC2F2'
        },
        (goals(officeData)),
        {
          name: 'Past 12 months',
          data: [officeData['twelve_months_total'] || 0],
          color: '#8EC2F2'
        },
        {
          name: 'Last Year',
          data: [officeData['last_year_total'] || 0],
          color: '#8EC2F2'
        }
      ]
    });
  }
}

$(document).on('render_async_load', () => {
  initOfficeChart($('#dashboard-office-report'));
});
