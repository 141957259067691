let initDonut = (element) => {
  if (element.length && element.data('chart-data').length > 0) {
    element.html('');
    let donut = Morris.Donut({
      element: element,
      data: element.data('chart-data'),
      colors: ['#00BAF0'],
      formatter: (y, data) => {
        return element.hasClass('skip-commas') ? '' : '$' + Morris.commas(y)
      }
    });

   donut.on('click', (i, row) => {
    let link = row.link;
    if(typeof link != 'undefined' &&
      link != null &&
      !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
      document.location.href = link
    }
   });

    donut.segments.forEach(element => {
      element.handlers['hover'].push(function (el) {
        let segment = donut.segments[el];
        $(segment.seg.node).attr('fill', '#CD5EA3');
        $(segment.arc.node).attr('stroke', '#CD5EA3');
        $(donut.segments[el].seg.node).mouseleave(() => {
          $(segment.seg.node).attr('fill', '#00BAF0');
          $(segment.arc.node).attr('stroke', '#00BAF0');
        })
      });
    });
  }
}

$(document).on('turbolinks:load', () => {
  $('.production-report-donut').each((i, item) => {
    initDonut($(item));
  });
});
