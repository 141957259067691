require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('@nathanvda/cocoon');

import 'bootstrap';
import 'bootstrap-datepicker';
import 'select2';
import '../stylesheets/application';
import '../js/vue/vue_main';

require.context('../images', true);

$(document).on('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
});

require('../js/main');
