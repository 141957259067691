import { initSelect2 } from './select2';
import { initSortable } from './sortable';

let separateAutocompleteIndexes = typeSelect => {
  var selectedType = typeSelect.find(':selected').val();

  var indexSelect = typeSelect.closest('.nested-fields')
    .find('.autocomplate-index-select');

  indexSelect.children('option').each((index, element) => {
    if ($(element).attr('class') != selectedType) {
      $(element).prop('disabled', true);
    } else {
      $(element).prop('disabled', false);
    }
  });
}

let autocompleteSelectEvents = () => {
  $('.autocomplate-type-select').change(e => {

    separateAutocompleteIndexes($(e.currentTarget));

    $(e.currentTarget).closest('.nested-fields')
      .find('.autocomplate-index-select')
      .val(null).trigger('change');
  });
};

let ajaxFields = () => {
  let triggers = $('.js-submit-on-change input, .js-submit-on-change select');
  triggers.off('change');
  triggers.on('change', e => {
    let target = e.currentTarget;
    let name = target.name.replace(/.*?\[(.*?)]/, '$1')
    $.ajax({
      type: 'post',
      url: target.form.action,
      data: { task: { [name]: target.value } }
    })
  });
  $('.datepicker').datepicker({ format: 'yyyy-mm-dd' });
}

let sendCompleted = (selector, data, succesFunc) => {
  $(selector).off('change')
  $(selector).on('change', e => {
    let target = e.currentTarget;
    $.ajax({
      type: 'post',
      url: target.form.action,
      data: data
    }).done(() => {
      if (succesFunc !== undefined) {
        succesFunc(target)
      }
      sendCompleted(selector, data, succesFunc);
      ajaxFields();
      initSortable($(selector).closest('.sortable').get(0));
    })
  });
}

$(document).on('turbolinks:load', () => {
  autocompleteSelectEvents();

  $('.autocomplate-type-select').each((index, element) => {
    separateAutocompleteIndexes($(element));
  })

  $('#autocomplete-container').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    initSelect2();
    autocompleteSelectEvents();
    var typeSelect = insertedItem.find('.autocomplate-type-select');
    separateAutocompleteIndexes(typeSelect);
  });

  $('.datepicker').datepicker({ format: 'yyyy-mm-dd', weekStart: 1, autoclose: true });

  ajaxFields();
  sendCompleted('.js-submit-completed-list input');
  sendCompleted('.js-submit-completed-general input', { type: 'general' });
  sendCompleted('.js-submit-completed-live input', { type: 'live' }, e => {
    $(e).closest('.list-row').hide()
  });
});
