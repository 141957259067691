let triggerSideFormChange = () => {
  if($('#transactions-filter-form').is(':visible')) {
    $('#transactions-filter-form').trigger('change');
  }
}

let triggerAllCheckboxes = (element, inputs, action) => {
  element.click((e) => {
    e.preventDefault();
    inputs.prop("checked", action);
    triggerSideFormChange();
  })
}

$(document).on('turbolinks:load', () => {
  $('#transactions-filter-form').change(() => {
    if($("input[name='q[aasm_state_in][]']:checked").length > 0){
      $('#transactions-filter-form').submit();
    } else {
      alert('Please select at least one status');
    }
  });

  triggerAllCheckboxes(
    $('#transactions-select-all-status'),
    $("input[name='q[aasm_state_in][]']"),
    true
  );
  triggerAllCheckboxes(
    $('#transactions-unselect-all-status'),
    $("input[name='q[aasm_state_in][]']"),
    false
  );
  triggerAllCheckboxes(
    $('#transactions-select-all-leads'),
    $("input[name='q[lead_id_in][]']"),
    true
  );
  triggerAllCheckboxes(
    $('#transactions-unselect-all-leads'),
    $("input[name='q[lead_id_in][]']"),
    false
  )

  $('.js-filter-date-range-link').click((e) => {
    e.preventDefault();
    let target = $(e.target);
    
    $(`#q_${target.data('field')}_gteq`).val(target.data('from'));
    $(`#q_${target.data('field')}_lteq`).val(target.data('to'));
    triggerSideFormChange()
  })

  $('.r1099-filter-input').change(() => {
    $('#r1099-filter-form').submit();
  });
})
