let companyFavourite = (event) => {
  let checkbox = event.target;
  $.ajax({
    url: '/v2/companies/' + $(checkbox).data('company-id') + '/toggle_favourite',
    method: 'PATCH',
    dataType: 'script',
    data: {
      favourite: $(checkbox).is(':checked')
    }
  });
}

$(document).on('turbolinks:load', () => {
  $('.js-company-favourite').click(companyFavourite);

  $('[data-send-test-quality-control-email]').click(e => {
    var $email, $qualityControl;
    e.preventDefault();
    $email = $('input[name="qc_test_email"]:visible').first();
    $qualityControl = $email.next('input[name="qc_test_id"]');
    $.ajax({
      url: '/company/send_quality_control_test_email',
      dataType: 'json',
      data: {
        email: $email.val(),
        quality_control_id: $qualityControl.val()
      },
      method: 'POST',
      success: (_data, _textStatus, _jqXHR) => {
        return window.location.reload();
      }
    });
    return false;
  });
});
