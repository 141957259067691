let listingTypeChangeListener = () => {
  let listing = $('.listing-items');
  let btns = $('.gallery-button, .list-button');
  let btnsWrapper = $('.listing-type');
  let setListingType = () => {
    btnsWrapper.removeClass('listing-gallery listing-list');
    listing.removeClass('listing-gallery listing-list');
    btnsWrapper.addClass(sessionStorage.getItem('listingType'));
    listing.addClass(sessionStorage.getItem('listingType'));
  }
  if (sessionStorage.getItem('listingType')) {
    setListingType();
  } else {
    sessionStorage.setItem('listingType', 'listing-gallery');
    setListingType();
  }

  btns.click(e => {
    let btn = $(e.currentTarget);
    let listingType = btn.data('listing-type');
    btns.removeClass('selected');
    btn.addClass('selected');
    sessionStorage.setItem('listingType', listingType);
    setListingType();
  });
}

$(document).on('turbolinks:load', e => {
  if ($('.listing').length > 0) {
    listingTypeChangeListener();
    $('footer').before($('.listing-public-link'));
    $('.loader').hide();
    $('.listing').show();
  }
});
