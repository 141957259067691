export default {
  props: {
    messagesData: Object
  },
  data() {
    return {
      loading: true,
      messagesCollection: this.messagesData.messages,
      showingMessage: null
    };
  },
  created() {
    this.loading = false;
  },
  watch: {},
  methods: {
    stopShowing() {
      this.showingMessage = null;
    }
  }
}
