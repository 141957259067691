import DOMPurify from 'isomorphic-dompurify';

export let blocksSwitcher = (firstBlock, secondBlock) => {
  if (firstBlock.is(':visible')) {
    secondBlock.show();
    firstBlock.hide();
  } else {
    secondBlock.hide();
    firstBlock.show();
  }
}

export let sanitizeText = text => {
  return DOMPurify.sanitize(text);
}
