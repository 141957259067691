export let swalConfirm = vm => {
  return vm.$swal({
    title: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#34c38f',
    cancelButtonColor: '#f46a6a',
    confirmButtonText: 'Yes'
  });
}

export let toastSuccess = (vm, message = 'Success!') => {
  toast(vm, message, 'success');
}

export let toastError = (vm, message = 'OOps, something went wrong!') => {
  toast(vm, message, 'error');
}

let toast = (vm, message, type) => {
  return vm.$swal({
    titleText: message,
    toast: true,
    icon: type,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    animation: false,
    customClass: `task-list-toast ${type}`
  });
}
