import AxiosRequest from './axios_request';
import Task from './task';
import DatePicker from 'vue2-datepicker';
import { swalConfirm, toastSuccess, toastError } from './sweetalert';
import _ from 'lodash';

export default {
  components: { Task, DatePicker },
  props: {
    currentUrl: String,
    bulkEditUrl: String,
    bulkDeleteUrl: String,
    templateType: String,
    queryParams: Object
  },
  data() {
    return {
      groupedTasks: [],
      assignees: [],
      selectedTasks: {},
      loading: true
    };
  },
  computed: {
    orderedTasks() {
      switch (this.templateType) {
        case 'taskLists':
          return this.groupedTasks.map(taskList => {
            taskList.tasks = _.orderBy(taskList.tasks, ['completed', 'priority']);
            return taskList;
          })
        case 'liveTasks':
          this.groupedTasks.forEach((transaction, transactionIndex) => {
            transaction.task_parentables.forEach((parentable, parentableIndex) => {
              Object.assign(
                this.findTasks({ transaction: transactionIndex, parentable: parentableIndex }),
                _.orderBy(parentable.tasks, ['completed', 'priority'])
              );
            });
          });
          return this.groupedTasks;
        default:
          return _.orderBy(this.groupedTasks, ['completed', 'priority']);
      }
    },
  },
  created() {
    let params = `?${$.param(this.queryParams)}`;
    if (this.templateType !== 'taskLists') {
      params += `&type=${this.templateType}`;
    }
    AxiosRequest({
      method: 'get',
      url: this.currentUrl + params
    }).then(response => {
      this.groupedTasks = response.data.grouped_tasks;
      if (this.templateType === 'taskLists') {
        this.assignees = response.data.assignees;
      }
      this.loading = false;
    }).catch(() => {
      toastError(this);
    });
  },
  methods: {
    addToSelected(findData) {
      let task = this.findTask(findData);
      if (task.selected) {
        this.$set(task, 'selected', false);
        this.$delete(this.selectedTasks, findData.taskId);
      } else {
        this.$set(task, 'selected', true);
        this.$set(this.selectedTasks, findData.taskId, findData);
      }
    },
    findTasks(indexes) {
      switch (this.templateType) {
        case 'liveTasks':
          return this.groupedTasks[indexes.transaction].task_parentables[indexes.parentable].tasks;
        case 'taskLists':
          return this.orderedTasks[indexes.parentable].tasks;
        default:
          return this.orderedTasks;
      }
    },
    findTask(findData) {
      return this.findTasks(findData.indexes).find(task => task.id === findData.taskId);
    },
    finderData(taskId, indexes = {}) {
      return JSON.stringify({ indexes: indexes, taskId: taskId });
    },
    findUserName(id) {
      return this.assignees.find(assignee => assignee.id === id).name;
    },
    hideTask(task) {
      this.$set(task, 'show', false);
    },
    updateGroupedTasks(responseTasks, field, val) {
      Object.keys(this.selectedTasks).forEach(key => {
        let localTask = this.findTask(this.selectedTasks[key]);
        if (field !== 'completed' && localTask.completed) return;
        let responseTask = responseTasks.find(task => task.id === this.selectedTasks[key].taskId);
        Object.assign(localTask, responseTask);
        if (field === 'completed') {
          this.handleCompletedField(this.findTask(this.selectedTasks[key]), field, val);
        }
      });
    },
    handleCompletedField(task, field, val) {
      if (this.templateType === 'liveTasks') {
        this.hideTask(task);
        this.$delete(this.selectedTasks, task.id);
      }
      if (field === 'completed' && val) {
        this.$set(task, 'selected', false);
        this.$delete(this.selectedTasks, task.id);
      }
    },
    bulkUpdateResponseHandler(response, field, value) {
      let message = '';
      if (field === 'completed') {
        if (value) {
          message += 'Selected task(s) completed now.';
        } else {
          message += 'Selected task(s) uncompleted now.';
        }
      } else {
        if (field === 'user_id') {
          message += `Set "assignee: ${this.findUserName(value)}" for selected task(s).`;
        } else {
          message += `Set "${field}: ${value.slice(0, 100)}" for selected task(s).`;
        }
      }
      toastSuccess(this, `Task(s) have been successfully updated. ${message}`);
    },
    bulkUpdate(field, value) {
      AxiosRequest({
        method: 'post',
        url: this.bulkEditUrl,
        data: {
          selected_tasks: Object.keys(this.selectedTasks),
          task: {
            [field]: value
          }
        }
      }).then(response => {
        this.bulkUpdateResponseHandler(response, field, value);
        this.updateGroupedTasks(response.data.tasks, field, value);
      }).catch(error => {
        this.bulkHandleErrorResponse(error);
      });
    },
    bulkAppendTitle(value) {
      AxiosRequest({
        method: 'post',
        url: this.bulkEditUrl,
        data: {
          selected_tasks: Object.keys(this.selectedTasks),
          action_type: 'append-title',
          task: {
            title: value
          }
        }
      }).then(response => {
        this.bulkUpdateResponseHandler(response, 'title', value);
        this.updateGroupedTasks(response.data.tasks, 'title', value);
      }).catch(error => {
        this.bulkHandleErrorResponse(error);
      });
    },
    bulkHandleErrorResponse(error) {
      if (error.response.status === 422) {
        return toastError(this, `Task(s) weren't updated. There is an error: ${error.response.data.errors}`);
      }
      toastError(this);
    },
    async bulkDelete() {
      await swalConfirm(this).then(response => {
        if (response['isConfirmed']) {
          AxiosRequest({
            method: 'delete',
            url: this.bulkDeleteUrl,
            data: {
              selected_tasks: Object.keys(this.selectedTasks)
            }
          }).then(() => {
            toastSuccess(this, 'Selected tasks have been successfully deleted.');
            Object.keys(this.selectedTasks).forEach(key => {
              let task = this.findTask(this.selectedTasks[key]);
              if (task.completed) return;
              this.hideTask(task);
            })
            this.selectedTasks = {};
          }).catch(() => {
            toastError(this);
          });
        }
      });
    },
  }
}
