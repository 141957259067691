$(document).on('turbolinks:load', () => {
  $('.task-template-search-select').change(() => {
    $('.task-template-search-select').closest('form').submit();
  });

  $('#agent_specific').on('click', () => {
    if ($('.js-agent-specific-checkbox').is(':checked')) {
      $('.js-agent-triggers-select').val(null).trigger('change');
      $('.js-agent-triggers-block').hide();
    } else {
      $('.js-agent-triggers-block').show();
    };
  });
});
