let lineChart = (id) => {
  let line_chart;

  if ($('#'+ id).length) {
    line_chart = Morris.Line({
      element: id,
      data: $('#' + id).data('chart-data').chart,
      xkey: 'month',
      ykeys: $('#' + id).data('chart-data').years,
      labels: $('#' + id).data('chart-data').years,
      smooth: false,
      parseTime: false
    });
  }

  return line_chart;
};

$(document).on('turbolinks:load', () => {
  lineChart('production-by-month');
});
