export let initDatepicker = () => {
  $('.power-search-datepicker').datepicker({
    format: 'yyyy-mm-dd',
    endDate: 'today',
    clearBtn: true
  });

  $('.month-date-datepicker').datepicker({
    format: 'mm/dd',
    clearBtn: true
  });

  $('.datepicker').datepicker({ format: 'yyyy-mm-dd' });
}

$(document).on('turbolinks:load', () => {
  initDatepicker();
});
