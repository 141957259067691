$(document).on('turbolinks:load', () => {
  $('.message-body iframe').on('load', function() {
    let iframe = $(window.top.document).find('.message-body iframe');
    iframe.height(iframe[0].contentWindow.document.body.scrollHeight + 20 + 'px');

    $('.message-body iframe').contents().find('a').each(function() {
      this.setAttribute('target','_blank');
    });
  });
});
