import AxiosRequest from './axios_request';
import { toastError } from './sweetalert';

export let fubGetContacts = query => {
  return AxiosRequest({
    method: 'get',
    url: `/contacts/fub_contacts?query=${query}`,
  }).then(response => {
    return response.data;
  }).catch(e => {
    if (e.response) {
      toastError(this);
    }
  });
};

export let createFubContact = suggestion => {
  let address = suggestion.address;
  return AxiosRequest({
    method: 'post',
    url: '/v2/contacts',
    data: {
      fub: true,
      contact: {
        first_name: suggestion.firstName,
        last_name: suggestion.lastName,
        phone: suggestion.phone,
        email: suggestion.email,
        created_by: suggestion.assignedTo,
        check_duplicate: true,
        address_attributes: {
          line_1: address && address.street,
          city: address && address.city,
          state: address && address.state,
          zip: address && address.code
        }
      }
    }
  }).then(response => {
    return response.data
  });
};
