import { sanitizeText } from './helpers';

let removeItemHandler = item => {
  let removeButton = item.find('.remove-item');
  removeButton.click(removeItem);
}

let removeItem = e => {
  let item = $(e.target).closest('.flex-fields-item');
  let wrapper = item.closest('.flex-fields-wrapper');
  item.hide('slow');
  setTimeout(() => {
    item.remove();
    handleItemType(wrapper, item, 'remove');
  }, 500);
}

let addItemHandler = (wrapper, item) => {
  item.show('slow');
  item.removeClass('hidden');
  handleItemType(wrapper, item, 'add');
}

let handleItemType = (wrapper, item, action) => {
  let type = wrapper.data('type');
  if (!!type) {
    let functionName = `${type}TypeHandler`;
    eval(functionName)(wrapper, item, action);
  }
}

let milestoneTypeHandler = (_wrapper, item, _action) => {
  item.find('.datepicker').datepicker({
    format: 'yyyy-mm-dd',
  }).change(setDaysAfter);
  item.find('.transaction_days_after').change(setDaysAfterDate);
}

let setDaysAfter = e => {
  $(e.target).closest('.flex-fields-item').find('.days-after').val(getDayDifference($(e.target)));
}

let getDayDifference = item => {
  let baseDate, date;
  if ($('#transaction_contract_date').val().length > 0 && $(item).val().length > 0) {
    baseDate = new Date($('#transaction_contract_date').val());
    date = new Date($(item).val());
    return (date.getTime() - baseDate.getTime()) / (1000 * 60 * 60 * 24);
  }
}

let setDaysAfterDate = e => {
  $(e.target).closest('.flex-fields-item').find('.transaction_date input').val(getDaysAfterDate($(e.target)));
}

let getDaysAfterDate = daysField => {
  let baseDate, days;
  if ($('#transaction_contract_date').val().length > 0 && $(daysField).val().length > 0) {
    baseDate = new Date($('#transaction_contract_date').val());
    days = parseInt($(daysField).val());
    baseDate.setTime(baseDate.getTime() + 1000 * 60 * 60 * 24 * days);
    return baseDate.toISOString().slice(0, 10);
  }
}

let assistantsTypeHandler = (wrapper, _item, _action) => {
  let items = wrapper.find('.flex-fields-item');
  items.each((i, el) => {
    $(el).find('span').text(i + 1);
  });
}

let setDateListeners = () => {
  $('#transaction_contract_date').change(() => {
    return $('.flex-fields-item').each((index, item) => {
      let baseDate, daysAfter;
      daysAfter = $(item).find('.transaction_days_after input').val();
      if (daysAfter) {
        baseDate = new Date($('#transaction_contract_date').val());
        baseDate.setTime(baseDate.getTime() + 1000 * 60 * 60 * 24 * daysAfter);
        return $(item).find('.transaction_date input').val(baseDate.toISOString().slice(0, 10));
      }
    });
  });
};

$(document).on('turbolinks:load', () => {
  $('.milestones .datepicker').change(setDaysAfter);
  $('.milestones .transaction_days_after').change(setDaysAfterDate);

  $('.add-item').click(e => {
    let wrapper = $(e.target).closest('.flex-fields-wrapper');
    let items = wrapper.find('.flex-fields');
    let exampleItem = sanitizeText(wrapper.data('template'));
    let addedItem;
    if (wrapper.data('insert') === 'append') {
      items.append(exampleItem);
      addedItem = items.find('.flex-fields-item:last-child');
    } else {
      items.prepend(exampleItem);
      addedItem = items.find('.flex-fields-item:first-child');
    }

    addItemHandler(wrapper, addedItem);
    removeItemHandler(addedItem);
  });

  $('.remove-item').click(removeItem);
  setDateListeners();
});
