import AxiosRequest from './vue/axios_request';
import { toastSuccess, toastError, swalConfirm } from './sweetalert';

$(document).on('turbolinks:load', () => {
  $('form#new_contact').submit(e => {
    e.preventDefault();
    let form = $('form#new_contact');
    let serializedData = decodeURIComponent(form.serialize());
    let contactData = encodeURI(`${serializedData}&contact[check_duplicate]=true`);
    return AxiosRequest({
      method: 'POST',
      url: '/v2/contacts',
      data: contactData
    }).then(response => {
      $('form#new_contact').off('submit');
      window.location.href = `/v2/contacts/${response.data.id}`;
    }).catch(error => {
      if (Object.keys(error.response.data.errors).includes('check_duplicate')) {
        let duplicateId = error.response.data.errors.check_duplicate;
        $('#use-existing-contact').click(() => {
          window.location.href = '/v2/contacts/' + duplicateId + '/edit';
        });
        $('#create-new-contact').click(() => {
          $('#contact-modal-submit').click();
          $('#use-existing-contact').off('click');
          $('#create-new-contact').off('click');
          $('#check-duplicates-modal').modal('hide');
          form.find(':input[type=submit]').attr('disabled', true);
          form.find(':input[type=submit]').val('Saving...');
          $('form#new_contact').off('submit');
          form.submit();
        });
        $('#check-duplicates-modal').modal();
        form.find(':input[type=submit]').attr('disabled', false);
        form.find(':input[type=submit]').val('Create Contact');
      } else {
        $('form#new_contact').off('submit');
        form.submit();
      }
    });
  });

  $('#selectAll').click(e => {
    if (e.target.checked) {
      $("input[name='contact_id[]']").each((_i, input) => {
        input.checked = true;
      });
    } else {
      $("input[name='contact_id[]']").each((_i, input) => {
        input.checked = false;
      });
    }
  });

  $('#export_contacts').click(() => {
    let contactsSelected = [];
    $("input[name='contact_id[]']").each((_i, input) => {
      if (input.checked) {
        return contactsSelected.push(input.value);
      }
    });
    if (contactsSelected.length < 1) {
      swalConfirm({
        title: 'Exporting Contacts to CSV',
        message: 'Please select at least one contact',
        confirmButton: 'Ok'
      });
    } else {
      window.location.href = `/v2/contacts/csv_export?${$.param({ ids: contactsSelected })}`;
    }
  });

  $('#merge_contacts').click(() => {
    let contactsSelected = [];
    $("input[name='contact_id[]']").each((_i, input) => {
      if (input.checked && input.getAttribute('data-organization') === null) {
        return contactsSelected.push(input.value);
      }
    });
    if (contactsSelected.length < 2) {
      swalConfirm({
        title: 'Merging Contacts',
        message: "Please select at least two contacts. (Companies can't be merged)",
        confirmButton: 'Ok'
      });
    } else {
      window.location.href = `/v2/contacts/merge_list?${$.param({ ids: contactsSelected })}`;
    }
  });

  $('#merge-contacts-button').click(async e => {
    let contactIds = [];
    let selectedContact = '';
    $("input[name='contact[id]']").each((_i, input) => {
      contactIds.push(input.value);
      if (input.checked) {
        selectedContact = input.value;
      }
    });
    let confirmMessage = 'Are you sure you want to make the selected contact a primary contact \
      and merge all the activity of the remaining contacts into the selected one? \
      (Remaining contacts will be deleted.)'
    await swalConfirm({
      title: 'Merging Contacts',
      message: confirmMessage,
      hasCancel: true
    }).then(result => {
      if (result.isConfirmed) {
        return AxiosRequest({
          method: 'POST',
          url: '/v2/contacts/merge',
          data: {
            merge: {
              selected: selectedContact,
              ids: contactIds
            }
          }
        }).then(response => {
          toastSuccess(response.data.success, 'success');
          setTimeout(() => {
            if (e.target.getAttribute('data-cleanup') !== null && e.target.getAttribute('data-cleanup').length > 0) {
              window.location.href = `/v2/contacts/duplicates`;
            } else {
              window.location.href = `/v2/contacts`;
            }
          }, 1500);
        }).catch(() => {
          toastError();
        });
      }
    });
  });

  $("input[name='contact[id]']").click(e => {
    if (e.target.checked) {
      $('#merge-contacts-button').prop('disabled', false);
    } else {
      $('#merge-contacts-button').prop('disabled', true);
    }
  });
});
