let toggleCheckboxListener = (toggleSelector, toggledSelector) => {
  $(toggleSelector).change(e => {
    if (e.target.checked) {
      $(toggledSelector).show('slow');
    } else {
      $(toggledSelector).hide('slow');
    }
  });
}

let toggleButtonListener = (toggleSelector, closestParent, toggledSelector) => {
  let toggle = $(toggleSelector);
  let parent = toggle.closest(closestParent);
  let toggledItem = parent.find(toggledSelector);
  toggle.click(() => {
    if (parent.hasClass('closed')) {
      parent.removeClass('closed');
      parent.addClass('opened');
      toggledItem.show('slow');
    } else if (parent.hasClass('opened')) {
      parent.removeClass('opened');
      parent.addClass('closed');
      toggledItem.hide('slow');
    }
  });
};

let toggleRadioListener = (toggleSelector, closestParent, toggledSelector) => {
  $(toggleSelector).change(e => {
    let toggledBlock = $(e.target).closest(closestParent).find(toggledSelector)
    if (e.target.value === '0') {
      toggledBlock.show('slow');
    } else {
      toggledBlock.hide('slow');
    }
  });
}

let asideNavbar = () => {
  let headerHeight = $('header').height();
  let navbar = $('.stickable');
  if (navbar.length) {
    let sticky = navbar.offset().top;

    let stickySidebarListener = () => {
      let offset = window.pageYOffset + headerHeight + 20;
      if (offset >= sticky) {
        navbar.addClass('sticky');
      } else {
        navbar.removeClass('sticky');
      }
    }
    let list = navbar.find('ul')

    window.onscroll = stickySidebarListener;

    $('.stickable a').click(e => {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $($(e.currentTarget).attr('href')).offset().top - 60
      }, 500);
      if (window.innerWidth <= 768) {
        navbar.removeClass('opened');
        navbar.addClass('closed');
        list.hide();
      }
      return false;
    });
  }
};

let resizeWindowListener = () => {
  let navbar = $('.aside-wrapper aside');
  navbar.removeClass('opened');
  navbar.addClass('closed');
  let list = navbar.find('ul');
  if (window.innerWidth <= 768) {
    list.hide();
  } else if (window.innerWidth >= 769) {
    list.show();
  }
};

let exclusiveSwitchesListener = switchesWrapper => {
  let switches = $(`${switchesWrapper} input[type='checkbox']`);
  switches.change(e => {
    let toggle = $(e.target);
    if (toggle.is(':checked')) {
      switches.prop('checked', false);
      toggle.prop('checked', true);
    }
  });
}

$(document).on('turbolinks:load', () => {
  toggleCheckboxListener(
    '#company_company_goals_enabled',
    '#company-goals-details'
  );
  toggleRadioListener(
    'input:radio[name="company[company_goal_calculation]"]',
    '#company-goals-details',
    '.manually-goal_calculation'
  );
  toggleButtonListener(
    '.aside-menu-toggle',
    'aside',
    'ul'
  );
  $('.smtp-settings .switch:not(.small)').change(e => {
    $(e.currentTarget).closest('.card').find('.card-content').toggle('slow');
  });

  asideNavbar();
  window.onresize = resizeWindowListener;
  $('.typeahead-bootstrap input').each((_i, el) => {
    $(el).attr('autocomplete', 'do-not-autofill');
  });
  exclusiveSwitchesListener('.exclusive-switches');
});
