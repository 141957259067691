import AxiosRequest from './axios_request';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import StarRating from 'vue-star-rating'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { toastError } from './sweetalert';
import { toastSuccess } from '../sweetalert';

export default {
  components: { VueBootstrapTypeahead, StarRating, DatePicker },
  data() {
    return {
      loading: false,
      contacts: [],
    };
  },
  watch: {},
  methods: {
    showModal() {
      this.loading = true;
      let contactsIds = this.$parent.$data.associatesCollection
        .filter(el => !el.should_delete)
        .map(contact => contact.associate_id);
      AxiosRequest({
        method: 'get',
        url: `/v2/contacts/finalize?${$.param({ ids: contactsIds })}`,
      }).then(response => {
        this.contacts = [];
        response.data.contacts.forEach(item => {
          this.contacts.push(Object.assign(item, { errorMessages: {} }));
        });
        this.loading = false;
      }).catch(e => {
        this.loading = false;
        if (e.response) {
          toastError(this);
        }
      });
    },
    modalSubmit() {
      AxiosRequest({
        method: 'put',
        url: '/v2/contacts/batch_update',
        data: {
          contacts: this.contacts
        }
      }).then(response => {
        toastSuccess(response.data.success, 'success');
        this.$bvModal.hide('finalize_contacts_modal');
        $('.transaction-form').off('submit').submit();
      }).catch(error => {
        if (error.response.status === 422) {
          error.response.data.forEach(item => {
            this.contacts.find(el => el.id === item.id).errorMessages = item.messages
          })
        } else {
          toastError(this);
        }
      });
    }
  }
}
