let barChart = (id) => {
  let bar;

  if ($('#'+ id).length && $('#'+ id).is(':empty')) {
   bar = Morris.Bar({
     element: id,
     data:  $('#' + id).data('report-data'),
     xkey: 'status',
     ykeys: ['amount'],
     xLabelMargin: 0,
     xLabelAngle: 60,
     padding: 30,
     labels: ['Amount'],
     barColors: ['90-#5296D0-#CD5EA3'],
     yLabelFormat: (y) => {
       return `$${Morris.commas(y)}`
     }
   });
  }

  return bar;
};

let updateChartData = (chart, params) => {
  $.ajax({
    url: chart.el.data('url'),
    dataType: 'json',
    data: params
  }).done(function(data) {
    chart.setData(data)
  });
}

$(document).on('render_async_load', () => {
  let salesVolumeBar;
  let netIncomeBar;

  if ($('#pipeline_price_report').length) {
    salesVolumeBar = barChart('pipeline_price_report');
  }

  if ($('#pipeline_broker_net_report').length) {
    netIncomeBar = barChart('pipeline_broker_net_report');
  }

  if ($('#pipeline_agent_net_report').length) {
    netIncomeBar = barChart('pipeline_agent_net_report');
  }

  $('.js-update-income-charts').click((e) => {

    e.preventDefault();

    let target = $(e.currentTarget);

    $('.js-update-income-charts.active').removeClass('active');
    target.addClass('active');

    if (salesVolumeBar) {
      updateChartData(
        salesVolumeBar,
        { group_by: $(e.currentTarget).data('group-by') }
      );
    }

    if (netIncomeBar) {
      updateChartData(
        netIncomeBar,
        { group_by: $(e.currentTarget).data('group-by') }
      );
    }
  });
});
