import rater from 'rater-js';

$(document).on('turbolinks:load ajax:success', () => {
  $('.star-rating').each((index, element) => {
    if ($(element).html().trim().length === 0) {
      let starRating = rater({
        element: element,
        max: 5,
        step: 1,
        starSize: 15,
        rateCallback(rating, done) {
          $(element).closest('.star-checkbox-block')
            .find('.star-checkbox-field')
            .val(rating);

          this.setRating(rating);

          done();
        }
      });

      starRating.setRating($(element).data('current-rating') || 0);
      starRating.disable();

      if ($(element).data('editable')) {
        starRating.enable();
      }
    }
  });
});
