let qualityControlBlockToggle = () => {
  let qualityBlock = $('#user-quality-control-block');
  if ($("[name='user[custom_review_email]']:checked").val() === '1') {
    qualityBlock.find('input, textarea').attr('disabled', false);
  } else {
    return qualityBlock.find('input, textarea').attr('disabled', true);
  }
};

let qualityBlockListner = () => {
  qualityControlBlockToggle();
  $("[name='user[custom_review_email]']").click(() => {
    qualityControlBlockToggle();
    if ($("[name='user[custom_review_email]']:checked").val() === '1') {
      CKEDITOR.instances['user_quality_control_attributes_email_text'].setReadOnly(false);
    }
  });
};

$(document).on('turbolinks:load', () => {
  $('#user_profile_attributes_payment_method, #company_payment_method').change(e => {
    switch (e.currentTarget.value) {
      case 'direct':
        $('#custom-bank-info').hide();
        $('#bank-info').show();
        break;
      case 'custom':
        $('#bank-info').hide();
        $('#custom-bank-info').show();
        break;
      default:
        $('#bank-info').hide();
        $('#custom-bank-info').hide();
    }
  });

  $('#user_profile_attributes_payment_method, #company_payment_method').trigger('change');

  $('.users .switch:not(.small)').change(e => {
    $(e.currentTarget).closest('.card').find('.card-content').toggle('slow');
  });

  qualityBlockListner();
});

