let changeIndex = (event, action) => {
  let btn = $(event.currentTarget);

  if (btn.closest('.sticky-column').length) {
    let index = action === 'show' ? 250 : 150
    btn.closest('.sticky-column').css('z-index', index);
  }
}

$(document).on('turbolinks:load', () => {
  $('.dropdown').on('show.bs.dropdown', e => {
    changeIndex(e, 'show');
  });
  $('.dropdown').on('hide.bs.dropdown', e => {
    changeIndex(e, 'hide');
  });
});
