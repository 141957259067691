import axios from 'axios';

export default ({ method, url, data = {} }) => {
  if (method != 'get') {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
  return axios({
    method: method,
    url: url,
    headers: {
      accept: 'application/json',
    },
    data: data
  })
}
