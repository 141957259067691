import { localizeAmount } from '../financials_calculation';
import { currentPriceAmount } from './price';
import { allFeesTotal } from './fees';
import { totalPayees } from './payees';
import { commissionNetAmount } from './commission_net'

export let grossAmount = () => {
  return parseFloat($('.transaction-commission-gross-field').val() || 0);
}

export let grossPercent = () => {
  return parseFloat($('.transaction-commission-percent-field').val() || 0);
}

export let switchGrossTableMode = tableMode => {
  if (tableMode) {
    $('.table-commission-gross-field').show();
    $('.table-commission-gross-text').hide();
    $('.transaction-commission-gross-field.basic').attr('readonly', true);
    $('.transaction-commission-percent-field').attr('readonly', true);
  } else {
    $('.table-commission-gross-field').hide();
    $('.table-commission-gross-text').show();
    $('.transaction-commission-gross-field.basic').attr('readonly', false);
    $('.transaction-commission-percent-field').attr('readonly', false);
  }
}

export let setGrossFromCommissionNet = () => {
  let amount = commissionNetAmount() + allFeesTotal('totalFeesBefore');
  amount += totalPayees('totalPayeesBefore');
  $('.transaction-commission-gross-field').val(amount.toFixed(2)).trigger('change');
}

export let setGrossAmount = () => {
  $('.transaction-commission-gross-field').val(calculateGrossAmount());
  setGross();
}

let setGrossPercent = () => {
  $('.transaction-commission-percent-field').val(calculateGrossPercent());
  setGross();
}

let setGross = () => {
  $('.table-commission-gross-percent').html(grossPercent());
  $('.transaction-commission-gross-field').val(grossAmount());
  $('.table-commission-gross-text').find('.amount').html(localizeAmount(grossAmount()));
}

let calculateGrossPercent = () => {
  return ((grossAmount() / currentPriceAmount()) * 100)
}

let calculateGrossAmount = () => {
  return (currentPriceAmount() * (grossPercent() / 100)).toFixed(2);
}

$(document).on('turbolinks:load', () => {
  if ($('.transaction-form').length > 0) {
    setGross();
  }

  $('.transaction-commission-gross-field').change(e => {
    $('.transaction-commission-gross-field').val($(e.currentTarget).val());
    setGrossPercent();
  });

  $('.transaction-commission-percent-field').change(e => {
    $('.transaction-commission-percent-field').val($(e.currentTarget).val());
    setGrossAmount();
  });
});
