import { sanitizeText } from './helpers';

export let initSelect2 = () => {
  $('.select2').select2({
    width: '100%'
  });

  $('.select2-tags').each((index, element) => {
    if($(element).data('option') &&
        $(element).find("option[value='" + $(element).data('option') + "']").length === 0) {
      let sanitizedOption = sanitizeText($(element).data('option'));
      $(element).append($('<option>', {
        value: sanitizedOption,
        text: sanitizedOption
      }));
      $(element).val($(element).data('option')).change();
    }
  });

  $('.select2-tags').select2({
    width: '100%',
    tags: true
  });

  $('.select2-without-search').select2({
    width: '100%',
    minimumResultsForSearch: -1
  });
}

$(document).on('turbolinks:load', () => {
  initSelect2();
})

$(document).on('turbolinks:before-cache', () => {
  $('select.select2, .select2-tags, .select2-without-search').each((index, element) => {
    $(element).select2('destroy');
  });
})
